.materials-list  {
  margin: 0;
  padding: 0;
  list-style: none;

  .partner-product {
    border-bottom: 1px solid #ccc;
    padding-top: 45px;
    padding-bottom: 25px;

    @media (min-width: $breakpoint-notebook) {
      align-items: start;
      padding-top: 60px;
      padding-left: 95px;
      padding-right: 95px;
      padding-bottom: 55px;
    }
  }

  .materials-list__item:last-child .partner-product {
    border-bottom: 0;
  }

  .partner-product__primary {
    @media (min-width: $breakpoint-notebook) {
      margin-left: 100px;
    }
  }

  .partner-product__title {
    font-size: 26px;
    line-height: 1;
    margin-bottom: 1.2rem;

    @media (min-width: $breakpoint-notebook) {
      font-size: 45px;
      margin-top: 55px;
      margin-bottom: 15px;
    }
  }

  .partner-product__subtitle {
    font-family: $font-helvetica;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 1.6rem;
    text-transform: none;

    @media (min-width: $breakpoint-notebook) {
      font-size: 18px;
      line-height: 1.3333;
      margin-bottom: 24px;
    }
  }

  .partner-product__info.rte {
    font-size: 14px;
    line-height: 18px;

    @media (min-width: $breakpoint-notebook) {
      font-size: 18px;
      line-height: 1.3333;
    }

    > p {
      margin-bottom: 1.6rem;

      @media (min-width: $breakpoint-notebook) {
        margin-bottom: 24px;
      }
    }

    > h5 {
      font-size: inherit;
      margin-bottom: 0.15em;
    }

    > p:last-child {
      @media (min-width: $breakpoint-notebook) {
        margin-bottom: 60px;
      }
    }
  }
}
