.page-title--inner.page-title--inner--mwng {
  margin-bottom: 0;
}

.page-title--title--mwng {
  font-size: 36px;
  line-height: 1;
  padding-top: 0;
  margin-top: 75px;
  margin-bottom: 40px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 75px;
    margin-top: 160px;
    margin-bottom: 100px;
  }
}

.mwng__intro {
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.mwng__intro-title {
  font-size: 26px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 5px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 45px;
    margin-bottom: 15px;
  }
}

.mwng__intro-body {
  font-size: 15px;
  line-height: 1.25;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 40px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 20px;
    line-height: 1.5;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 100px;
  }
}

.mwng__intro-middle {
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.mwng__intro-middle-title {
  font-size: 26px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 5px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 60px;
    margin-bottom: 15px;
  }
}

.mwng__intro-middle-body {
  font-size: 16px;
  line-height: 1.25;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 40px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 25px;
  }
}

.mwng__outro {
  margin-left: auto;
  margin-right: auto;
  max-width: $max-site-width;
  padding-bottom: 28px;

  @media (min-width: $breakpoint-notebook) {
    align-items: center;
    display: flex;
    padding-bottom: 6px;
  }
}

.mwng__outro-image {
  @media (min-width: $breakpoint-notebook) {
    flex: 0 0 auto;
    width: 57%;
  }
}

.mwng__outro-primary {
  padding-left: $gutter-side-mobile;
  padding-right: $gutter-side-mobile;
  margin-top: 25px;
  padding-bottom: 75px;

  @media (min-width: $breakpoint-notebook) {
    flex: 1 1 auto;
    margin-top: 0;
    padding-left: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.mwng__outro-title {
  font-size: 26px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 5px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 45px;
    margin-bottom: 15px;
  }
}

.mwng__outro-body {
  font-size: 14px;
  line-height: 1.286;

  @media (min-width: $breakpoint-notebook) {
    font-size: 18px;
    line-height: 1.389;
    max-width: 450px;
  }
}

.mwng__outro-actions {
  margin-top: 25px;

  @media (min-width: $breakpoint-notebook) {
    margin-top: 34px;
  }
}

.mwng__outro-action {

}
