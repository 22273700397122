$height-venue-fullscreen: 100vh;

$height-sticky-pace-none: 0;
$height-sticky-pace-normal: 160vh;
$height-sticky-pace-slow: 240vh;
$height-sticky-pace-for-image-flips: 320vh;
$height-sticky-pace-image-flip-trigger: 100vh;
$height-sticky-pace-image-flip-trigger-reset: 100px;

$height-tablist-mobile_px: 110px;
$height-tablist-tablet_px: 154px;

$scrolly_tint_padding_px: 80px;
$scrolly_stage_threshold_px: 60px;
$tablist_diff_image_flip_px: 168px;
$tablist_separation_px: 20px;
$tablist_sticky_top_mobile: $header-mobile-height;
$tablist_sticky_top_notebook: $header-desktop-height;

@media (max-height: #{$vh-min-mobile-modern - 1}) {
  .scrollama__debug-offset {
    display: none;
  }

  #sources {
    background-image: url('images/about/white-material-pile-extra-narrow@4x.jpg');
    background-size: cover;
    background-position: center center;
  }

  .about-scrolly__background {
    display: none;
  }

  .about-scrolly__foreground {
    background-color: rgba(255, 255, 255, 0.9);
    margin: 50px 0;
    padding: 10px 0;
  }

  .about-scrolly__chapter__item {
    margin: 50px 0;
  }
}

@media (min-height: $vh-min-mobile-modern) {
  .about-scrolly {
    .about-feature {
      margin-bottom: 0;
    }
  }

  .about-scrolly__background {
    @include position-sticky();
    top: 0;
    left: 0;
    width: 100%;
    height: $height-venue-fullscreen;
  }

  .about-scrolly__background__image {
    display: block;
    postion: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .about-scrolly__background__tint-container {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .about-scrolly__background__tint {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    transform: scale(1, 0.4);
    transition: all 0.2s ease;
  }

  .about-scrolly__foreground {
    margin-top: -$height-venue-fullscreen;
  }

  .about-scrolly__chapter {
    position: relative;
    z-index: 1;
  }

  .about-scrolly__chapter--sticky-pace-none {
    min-height: $height-sticky-pace-none;
  }

  .about-scrolly__chapter--sticky-pace-normal {
    min-height: $height-sticky-pace-normal;
  }

  .about-scrolly__chapter--sticky-pace-slow {
    min-height: $height-sticky-pace-slow;
  }

  .about-scrolly__chapter--sticky-pace-for-image-flips {
    min-height: $height-sticky-pace-for-image-flips;
  }

  .about-scrolly__chapter--sticky-pace-image-flip-trigger {
    min-height: $height-sticky-pace-image-flip-trigger;
  }

  .about-scrolly__chapter--sticky-pace-image-flip-trigger-reset {
    min-height: $height-sticky-pace-image-flip-trigger-reset;
  }

  .about-scrolly__sticky {
    @include position-sticky();
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }

  .about-scrolly__sticky--tablist {
    opacity: 0;
    top: $tablist_sticky_top_mobile;
    transition: all 0.2s ease;

    @media (min-width: $breakpoint-notebook) {
      top: unquote('max(#{$tablist_sticky_top_notebook}, calc(#{50%} - #{$height-tablist-tablet_px + $tablist_separation_px + $tablist_diff_image_flip_px}))');
    }

    #sources[data-active-tint="fullscreen-with-tablist"] & {
      opacity: 1;
    }
  }

  .about-scrolly__chapter__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: $height-venue-fullscreen;

    .about-scrolly__sticky--top-with-tablist & {
      padding-top: $height-tablist-mobile_px;

      @media (min-width: $breakpoint-tablet) {
        padding-top: $height-tablist-tablet_px;
      }
    }

    .about-scrolly__sticky--top-with-tablist--allow-overlap & {
      padding-top: $height-tablist-mobile_px;

      @media (min-width: $breakpoint-tablet) {
        padding-top: 0;
      }
    }
  }

  .about-scrolly__chapter__item {
    width: 100%;
  }

  .about-scrolly__chapter__item--conservative-width {
    width: 60%;

    @media (min-width: $breakpoint-smartphone-portrait) and (max-width: $vw-max-mobile-modern - 1) {
      width: 75%;
    }

    @media (min-height: $vh-min-clingy-tablist) {
      width: 85%;
    }

    @media (min-height: $vh-min-clingy-tablist) and (min-width: $breakpoint-tablet) {
      width: 100%;
    }
  }

  .about-scrolly__step p {
    margin-bottom: 0;
  }

  .about-scrolly__step__inner {
    transition: opacity 0.2s ease;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
  }

  .about-scrolly__step {
    > .about-scrolly__step__inner {
      opacity: 0;
    }
  }


  .about-scrolly__step.always-visible-below-stage-top {
    &[data-prox-sources-stage-top="below"][data-prox-sources-stage-bot="below"],
    &[data-prox-sources-stage-top="below"][data-prox-sources-stage-bot="on"],
    &[data-prox-sources-stage-top="below"][data-prox-sources-stage-bot="above"] {
      > .about-scrolly__step__inner {
        opacity: 1;
      }
    }
  }


  .about-scrolly__step.visible-below-stage-top-and-on-stage-bot {
    &[data-prox-sources-stage-top="below"][data-prox-sources-stage-bot="on"],
    &[data-prox-sources-stage-top="below"][data-prox-sources-stage-bot="above"] {
      > .about-scrolly__step__inner {
        opacity: 1;
      }
    }
  }

  .about-scrolly__step.only-visible-inside-stage {
    &[data-prox-sources-stage-top="below"][data-prox-sources-stage-bot="above"] {
      > .about-scrolly__step__inner {
        opacity: 1;
      }
    }
  }

  .about-scrolly__step.only-visible-on-stage-below-tablist {
    &[data-prox-sources-tablist-top="below"][data-prox-sources-stage-bot="above"] {
      > .about-scrolly__step__inner {
        opacity: 1;
      }
    }
  }

  .about-scrolly__step.always-visible-above-stage-bottom {
    &[data-prox-sources-stage-top="below"][data-prox-sources-stage-bot="above"],
    &[data-prox-sources-stage-top="on"][data-prox-sources-stage-bot="above"],
    &[data-prox-sources-stage-top="above"][data-prox-sources-stage-bot="above"] {
      > .about-scrolly__step__inner {
        opacity: 1;
      }
    }
  }

////////////////////////////////////////////////////////////////////////////////
// Helpful debug styles during WIP

  .debug-about__toggle,
  .debug-about__top_link {
    position: fixed;
    z-index: 99999;
    bottom: 10px;
    padding: 10px;
    font-size: 16px;
    line-height: 1.4;
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    background: #333;
    border: 1px solid #444;
    color: white;

    &:hover {
      color: rgb(180, 255, 180);
    }

    &:focus, &:active {
      color: rgb(80, 255, 80);
    }
  }

  .debug-about__toggle {
    right: calc(5em + 20px);

    kbd {
      font-family: sans-serif;
      background: #111;
      border-radius: 2px;
      color: #999;
      padding: 4px;
      margin-left: 0.5em;
    }
  }

  .debug-about__top_link {
    right: 10px;
    width: 5em;

    &:hover, &:focus, &:active {
      text-decoration: underline;
    }
  }

  body:not(.debug) {
    .scrollama__debug-offset {
      display: none;
    }
  }

  body.debug {
    .about-scrolly__chapter {
      box-shadow: inset 0px 0px 20px 20px black;
      background: repeating-linear-gradient(180deg, transparent, transparent 50px, rgba(0, 0, 0, 0.2) 50px, rgba(0, 0, 0, 0.2) 100px);
    }

    [data-scrolly-step-type="about-chapter"] {
      outline: 4px solid black;
    }

    [data-scrolly-step-type="about-content-element"] {
      outline: 16px ridge red;
    }

    [data-step="reveal-disclosures"] {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: -8px;
        left: 0;
        right: 0;
        border-top: 12px dotted rgba(60, 120, 60, 1.0);

        @media (min-width: $breakpoint-notebook) {
          left: -160px;
          right: -160px;
        }
      }
    }

    .about-scrolly__chapter__container {
      background-color: rgba(150, 150, 150, 0.6);
      outline: 10px dashed red;
    }

    .about-scrolly__step {
      outline: 16px ridge red;
    }

    .about-scrolly__step[data-prox-sources-stage-top="on"],
    .about-scrolly__step[data-prox-sources-stage-bot="on"] {
      background-color: rgba(255, 255, 0, 0.5);
    }

    .about-scrolly__step[data-prox-sources-stage-top="above"],
    .about-scrolly__step[data-prox-sources-stage-bot="below"] {
      background-color: rgba(255, 0, 0, 0.5);
    }

    .about-scrolly__step[data-prox-sources-stage-top="below"][data-prox-sources-stage-bot="above"] {
      background-color: rgba(120, 255, 120, 0.5)
    }

    .about-scrolly__step.only-visible-on-stage-below-tablist {
      &[data-prox-sources-tablist-top="below"][data-prox-sources-stage-bot="above"] {
        background-color: rgba(100, 100, 255, 0.5);
      }
    }

    .scrollama__debug-offset[style] {
      opacity: 0.9;
    }

    .scrollama__debug-offset p[style] {
      position: relative;
      top: -1.0em;
      display: inline-block;
      padding: 0 0.5em !important;
      background-color: #333;
      color: white !important;

      span {
        display: block;
        float: right;
        margin-left: 1.0em;
        max-width: 5em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
