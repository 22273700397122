/**
 * Theme Colors
 *
 * Named with http://chir.ag/projects/name-that-color
 */

// Colors
$color-gray-dark: rgb(17, 17, 17);
$color-green: rgb(67, 176, 43);
$color-bg: rgb(26, 26, 26);
// $color-body: rgb(57, 53, 50);
$color-body: #111;
$color-white: rgb(255, 255, 255);
$color-black: rgb(0,0,0);
$color-white-dark: rgb(187, 187, 187);
$color-fresh-turf: rgb(171, 209, 83);
$color-orange: rgb(250, 84, 0);
$color-moonshot: rgb(0, 58, 112);
$color-track-spike: rgb(156, 165, 174);
$color-gray-light: rgb(225, 228, 231);
$color-gray-xtra-light: rgb(235, 235, 235);
$color-gray-additional: rgb(243, 243, 243);
$color-gray-smoke: rgb(153, 153, 153);
$color-gray-additional-2: rgb(123, 123, 123); // #7b7b7b
$color-gray-fossil: rgb(103, 103, 103);

$color-calypso: rgb(57, 108, 148);
$color-fountain: rgb(89, 166, 191);
$color-stone: rgb(121, 125, 130);

// Custom fonts
$font-trade-gothic-bold-condensed: "Trade Gothic For Nike 365 Bold Condensed", Helvetica, sans-serif;
$font-helvetica: Helvetica, sans-serif;
$font-palatino: 'Palatino LT W01 Roman', Georgia, serif;

// Transition effects
$transition-duration-default: 0.2s;

// Gutters and breakpoints
$gutter-side-tile: 15px;
$gutter-side-mobile: 20px;
$gutter-side-tablet: 30px;
$gutter-side-desktop: 30px;

$breakpoint-smartphone-portrait: 375px;
$breakpoint-smartphone-landscape: 480px;
$breakpoint-tablet: 768px;
$breakpoint-notebook: 992px;
$breakpoint-desktop: 1200px;
$breakpoint-desktop-wide: 1366px;

$max-site-width: 1500px;

$vh-min-mobile-conservative: 400px;
$vw-max-mobile-modern: 480px;
$vh-min-mobile-modern: 600px;
$vh-min-clingy-tablist: 800px;

// Various helper variables
$header-letter-spacing: 0em;
// $header-letter-spacing: 0.075em;
$base-font-size-mobile: 14px;
$base-font-size-desktop: 20px;
$header-desktop-height: 72px;
$header-mobile-height: 50px;
$header-logo-width: 46px;
$header-mobile-close-width: 35px;
$mobile-menu-mobile-submenu-toggle-width: 4.3rem;
