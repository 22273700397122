.callout-default {
  @media (min-width: $breakpoint-tablet) {
    display: flex;
  }
}

.callout-default--left {
  margin-top: 4rem;

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 4rem;
    margin-top: 6rem;
  }
}

.callout-default__image-wrapper {
  @media (max-width: $breakpoint-tablet - 1px) {
    margin-left: -$gutter-side-mobile;
    margin-right: -$gutter-side-mobile;
  }

  @media (min-width: $breakpoint-tablet) {
    flex: 0 0 60%;
    position: relative;
    overflow: visible;
    min-height: 560px;
  }
}

.callout-default__image {
  @media (min-width: $breakpoint-tablet) {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    bottom: 0;
    max-width: none;
    width: auto;

    .callout-default--left & {
      left: auto;
      right: 0;
    }
  }
}

.callout-default__primary {
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;

  @media (min-width: $breakpoint-tablet) {
    align-self: center;
    flex: 1 1 auto;
    margin-bottom: 10rem;
  }

  @media (min-width: $breakpoint-tablet) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: $breakpoint-desktop) {
    .callout-default--left & {
      padding-right: 100px;
      padding-left: 0;
    }

    .callout-default--right & {
      padding-left: 100px;
      padding-right: 0;
    }
  }

  .callout-default--left & {
    @media (max-width: $breakpoint-tablet - 1px) {
      margin-bottom: 8.5rem;
    }
  }
}

.callout-default__title {
  color: $color-gray-additional-2;
  font-family: $font-palatino;
  font-size: 26px;
  line-height: 1.1389;
  margin-top: 0;
  margin-bottom: 15px;
  text-transform: none;

  @media (min-width: $breakpoint-tablet) {
    font-size: 36px;
    line-height: 1.1389;
    margin-bottom: 20px;
  }
}

.callout-default__body {
  font-size: 16px;
  color: $color-gray-additional-2;
}

.callout-default__link {
  color: $color-gray-additional-2;
  font-size: 14px;
  line-height: 1.4167;
  text-transform: uppercase;

  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
    line-height: 1.2778;
  }
}
