.partner-outro {

  @media (min-width: $breakpoint-tablet) {
    display: flex;
  }
}

.partner-outro__primary {
  align-self: center;
  flex: 1 1 auto;
  padding-top: 4.5rem;
  padding-bottom: 5rem;

  @media (min-width: $breakpoint-tablet) {
    padding-left: 20px;
    padding-right: 20px;

    .container {
      width: 100%;
    }
  }

  @media (min-width: $breakpoint-notebook) {
    padding-left: 60px;
    padding-right: 60px;
    max-width: 750px;
  }
}

.partner-outro__image-wrapper {
  flex: 0 0 50%;
}

.partner-outro__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.partner-outro__logo {
  margin-bottom: 24px;
  width: 150px;

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 40px;
    width: 220px;
  }
}

.partner-outro__title {
  font-size: 20px;
  line-height: 1.1667;
  margin-top: 0;

  @media (min-width: $breakpoint-tablet) {
    font-size: 30px;
    margin-bottom: 3px;
  }
}

.partner-outro__body.rte {
  font-size: 14px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 18px;
    line-height: 25px;
  }
}