.block-storiesblock {
  margin-bottom: 35px;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 100px;
  }
}

.stories--wrapper {
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: $breakpoint-notebook) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -$gutter-side-tile;
    margin-right: -$gutter-side-tile;
  }
}

.stories--wrapper--partners {

  @media (min-width: $breakpoint-notebook) {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.stories--item {
  margin-bottom: 10px;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 30px;
    padding-left: $gutter-side-tile;
    padding-right: $gutter-side-tile;
    width: 50%;
  }
}

// Legacy Project Page

.page-title.page-title--project {
  margin-bottom: 3.5rem;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 5.5rem;
  }
}

.page-title--title--project {
  margin-top: 0;
  margin-bottom: 0.25em;
  max-width: 900px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 45px;
    line-height: 1;
    padding-top: 0;
    margin-top: 0.5em;
    margin-bottom: 0;
  }
}
.page-title--inner--project {
  margin-top: 88px;
  margin-bottom: 0;
}

.page-title--intro--project {
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 18px;
  margin-bottom: 3rem;
  text-transform: uppercase;

  @media (min-width: $breakpoint-notebook) {
    font-size: 24px;
    line-height: 1.667em;
    margin-bottom: 5.5rem;
  }
}

.page-title--intro--project.page-title--intro--location,
.page-title--intro--project.page-title--intro--surface-type {
  line-height: 1.2em;
}

.page-title--intro--project.page-title--intro--location {
  margin-top: 2px;
  margin-bottom: 0;
}

.page-title--intro--project.page-title--intro--surface-type {
  margin-bottom: 5.2rem;
}

.page-title--banner {
  height: 300px;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  @media (min-width: $breakpoint-notebook) {
    height: 650px;
  }
}

.page-title--banner--project {
  background-size: cover;
  background-position: 50% 50%;
  img {
    visibility: hidden;
  }
}
