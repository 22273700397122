.meet-partners {
  background-color: $color-gray-additional;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.meet-partners__inner {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7rem;
  padding-bottom: 6.5rem;

  @media (min-width: $breakpoint-tablet) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: $breakpoint-desktop) {
    padding: 100px;
  }
}

.meet-partners__title {
  font-size: 25px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 1.6rem;

  @media (min-width: $breakpoint-tablet) {
    font-size: 35px;
  }

  @media (min-width: $breakpoint-notebook) {
    font-size: 45px;
    margin-bottom: 2rem;
  }
}

.meet-partners__body {
  font-size: 14px;
  line-height: 1.5;

  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
    line-height: 1.389;
  }
}

.meet-partners__link {
  display: inline-block;
  margin-top: 0.6rem;
  text-transform: uppercase;

  @media (min-width: $breakpoint-tablet) {
    margin-top: 1rem;
  }
}

.meet-partners__primary {
  margin-bottom: 2.5rem;

  @media (min-width: $breakpoint-tablet) {
    flex: 1 1 auto;
    margin-bottom: 0;
    max-width: 380px;
  }

  @media (min-width: $breakpoint-notebook) {
    max-width: 480px;
  }
}

.meet-partners__secondary {
  @media (min-width: $breakpoint-tablet) {
    flex: 0 1 auto;
    margin-bottom: 3rem;
  }
}

.meet-partners__logos {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -$gutter-side-mobile;
  padding: 12px 0;

  @media (min-width: $breakpoint-tablet) {
    max-width: 600px;
    margin: 0;
  }
}

.meet-partners__logo {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  width: calc(100% / 3);
}
