// Page-level elements

#page {
  background-color: $color-white;

  .homepage & {
  	position: relative;

  	@media (min-width: $breakpoint-notebook) {
  		flex: 1 1 auto;
  	}
  }

  .body--homepage & {
    overflow-x: hidden;
  }
}
