.page-title.page-title--bg-image.page-title--material {
  margin-bottom: 0;
}

.material__nav {
  @include position-sticky();
  top: $header-desktop-height;
  z-index: 3;
  background-color: $color-white;
  outline: 2px solid rgba($color-track-spike, 0.3);

  @media (max-width: $breakpoint-notebook - 1px) {
    display: none;
  }
}

.material__nav-list {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    margin-left: 30px;
    margin-right: 30px;
    position: relative;

    &:after {
      border-right: 1px solid rgb(152, 152, 152);
      content: "";
      height: 20px;
      margin-bottom: 5px;
      top: calc(50% - 3px);
      width: 0;
      right: -30px;
      position: absolute;
      transform: translateY(-50%);
    }

    &:last-child:after {
      display: none;
    }
  }
}

.material__nav-link {
  border-bottom: 4px solid transparent;
  color: rgb(102, 102, 102);
  display: block;
  font-size: 18px;
  line-height: 1;
  padding: 24px 0;
  text-decoration: none;

  &.active {
    border-color: black;
  }
}

.material__page-title-region {
  margin-left: auto;
  margin-right: auto;
  max-width: 44em;
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: $breakpoint-notebook) {
    margin-top: 114px;
    margin-bottom: 55px;
  }
}

.material__title {
  font-size: 36px;
  line-height: 1;
  margin-bottom: 5px;
  margin-top: 0;

  @media (min-width: $breakpoint-notebook) {
    font-size: 75px;
    margin-bottom: 10px;
  }
}

.material__intro {
  font-size: 16px;
  line-height: 1.25;

  @media (min-width: $breakpoint-notebook) {
    font-size: 20px;
    line-height: 1.5;
  }
}
