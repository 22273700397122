$circularity-underline-transition-duration: 0.75s;
$circularity-underline-transition-delay: 0.5s;
$circularity-underline-transition-timing-function: ease-out;

@mixin circularity-step-disclosure($bgcolor: $color-moonshot, $top: auto, $left: auto, $pull_left: 0) {
  .about-circularity__disclosure {
    @media (min-width: $breakpoint-notebook) {
      top: $top;
      left: $left;
      margin-top: -1.2em;
      margin-left: $pull_left * 1.5;
    }
  }

  .about-circularity__disclosure__summary {
    top: $top;
    left: $left;
    margin-top: -1em;
    margin-left: $pull_left;

    @media (min-width: $breakpoint-notebook) {
      top: auto;
      left: auto;
      margin-top: 0;
      margin-left: 0;
    }
  }

  .about-circularity__disclosure__details {
    background-color: $bgcolor;
  }
}

.about-circularity {
  position: relative;
  max-width: 560px;
  margin: 50px auto;

  ol {
    list-style: none;

    li {
      display: block;
    }
  }

  summary {
    cursor: pointer;
  }

  summary::-webkit-details-marker {
    display: none
  }
}

.about-circularity__loop {
  position: relative;
  height: 0;
  padding-top: percentage(530/530);
  overflow: hidden;
}

.about-circularity__loop_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('images/about/circularity-loop.png');
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.about-circularity__swoosh {
  position: absolute;
  bottom: 50%;
  left: 50%;
  margin-left: -26px;
  margin-bottom: 5px;
}

.about-circularity__heading {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: 5px;
  text-align: center;
  font-size: 10px;
  color: $color-gray-smoke;

  @media (min-width: $breakpoint-tablet) {
    font-size: 16px;
  }
}

.about-circularity__disclosure {
  font-size: 16px;

  @media (min-width: $breakpoint-notebook) {
    position: absolute;
  }
}

.about-circularity__disclosure__summary {
  position: absolute;

  @media (min-width: $breakpoint-notebook) {
    position: static;
  }
}

.about-circularity__step-1 {
  @include circularity-step-disclosure($color-moonshot, 40%, 20%, -2.15em);
}

.about-circularity__step-2 {
  @include circularity-step-disclosure($color-calypso, 17%, 51%, -1.5em);
}

.about-circularity__step-3 {
  @include circularity-step-disclosure($color-fountain, 50%, 79%, -2.45em);
}

.about-circularity__step-4 {
  @include circularity-step-disclosure($color-stone, 76%, 41%, -2.3em);
}

.about-circularity__substep {
  position: absolute;
  padding: 0;
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 13px;
  line-height: 1;
  text-transform: uppercase;

  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
  }
}

.about-circularity__step-2 .about-circularity__substep {
  top: 24%;
  left: 93%;
  color: $color-calypso;

  @media (min-width: $breakpoint-tablet) {
    top: 15%;
    left: 101%;
  }
}

.about-circularity__step-3 .about-circularity__substep {
  width: 4em;
  top: 94%;
  right: 13%;
  margin-right: -2em;
  color: $color-fountain;
}

.about-circularity__step-4 .about-circularity__substep {
  width: 4em;
  top: 92%;
  right: 86%;
  color: $color-stone;

  @media (min-width: $breakpoint-tablet) {
    top: 84%;
    right: 93%;
  }
}

.about-circularity__disclosure__summary {
  padding: 1.0em 1.0em;
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 16px;
  line-height: 0.9;
  color: $color-white;
  text-transform: uppercase;

  span {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 2px;
      background-color: $color-white;
      transform: scale(0, 1);
      transform-origin: center left;
      transition-property: transform;
      transition-duration: $circularity-underline-transition-duration;
      transition-delay: 0;
      transition-timing-function: $circularity-underline-transition-timing-function;
    }
  }

  .about-circularity__step-1 & {
    span:after {
      transition-delay: 0 * $circularity-underline-transition-delay;
    }
  }

  .about-circularity__step-2 & {
    span:after {
      transition-delay: 1 * $circularity-underline-transition-delay;
    }
  }

  .about-circularity__step-3 & {
    span:after {
      transition-delay: 2 * $circularity-underline-transition-delay;
    }
  }

  .about-circularity__step-4 & {
    span:after {
      transition-delay: 3 * $circularity-underline-transition-delay;
    }
  }

  .about-scrolly__step--has-been-entered & {
    span:after {
      transform: scale(1, 1);
    }
  }

  @media (min-width: $breakpoint-tablet) {
    font-size: 24px;
  }
}

.about-circularity__disclosure__details {
  z-index: 3;
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  width: auto;
  min-height: calc(100% + 20px);
  padding: 110px 25px 40px;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: $color-white;
  border-radius: 4px;

  @media (min-width: $breakpoint-notebook) {
    top: auto;
    left: auto;
    min-height: 0;
    padding: 30px 25px 15px;
    text-align: left;

    .about-circularity__step-1 & {
      top: -105px;
      right: -40px;
      width: 370px;
    }

    .about-circularity__step-2 & {
      top: -18px;
      left: 10px;
      width: 350px;
    }

    .about-circularity__step-3 & {
      top: -8px;
      left: 14px;
      width: 340px;
    }

    .about-circularity__step-4 & {
      top: -55px;
      left: -70px;
      width: 300px;
    }
  }
}

.about-circularity__disclosure__details__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  padding: 15px;
  border: 0;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  color: $color-white;
  background-color: transparent;
}

.about-circularity__disclosure__details__heading {
  margin-top: 25px;
  font-size: 26px;
  line-height: 1;

  @media (min-width: $breakpoint-notebook) {
    display: inline-block;
    margin-top: 0;
    font-size: 36px;
  }
}

.about-circularity__disclosure__details__image {
  position: absolute;
  top: 35px;
  left: 50%;
  margin-left: -45px;

  @media (min-width: $breakpoint-notebook) {
    display: inline-block;
    position: static;
    top: auto;
    left: auto;
    margin-left: 1em;
    margin-top: -24px;
    width: 60px;
  }
}
