$partner-five-up-max-width: 240px;
$partner-five-up-max-n-up: 5;

.block--title--partner {
  font-size: 36px;
  line-height: 1;
  margin-bottom: 5rem;

  @media (min-width: $breakpoint-notebook) {
    font-size: 45px;
  }

  .block--partner--five_up & {
    font-size: 26px;
    max-width: 208px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $breakpoint-notebook) {
      font-size: 45px;
      max-width: none;
    }
  }
}

.partners-list__wrapper {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5rem;
  max-width: 760px;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 10rem;
  }

  .block--partner--five_up & {
    max-width: $max-site-width;
    margin-bottom: 3rem;

    @media (min-width: $breakpoint-notebook) {
      margin-bottom: 10rem;
    }
  }
}

.partners-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -$gutter-side-tile 0;
  padding: 0;
  width: 100%;

  .block--partner--five_up & {
    margin-left: auto;
    margin-right: auto;
    max-width: $partner-five-up-max-width * $partner-five-up-max-n-up;

    @media (min-width: $breakpoint-tablet) {
      justify-content: center;
    }
  }
}

.partners-list__partner {
  height: 106px;
  flex: 0 0 auto;
  padding-left: $gutter-side-tile;
  padding-right: $gutter-side-tile;
  margin-bottom: 3rem;
  width: 50%;

  @media (min-width: $breakpoint-tablet) {
    width: 33.33%;
  }

  .block--partner--five_up & {
    margin-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;

    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 3rem;
      width: percentage(1/$partner-five-up-max-n-up);
      max-width: $partner-five-up-max-width;
    }

    @media (min-width: $breakpoint-notebook) {
      padding-left: $gutter-side-tile;
      padding-right: $gutter-side-tile;
    }
  }
}

.block--partner--five_up {
  @media (min-width: $breakpoint-tablet) {
    .partners-list--count-1 .partners-list__partner {
      width: percentage(1/1);
    }
    .partners-list--count-2 .partners-list__partner {
      width: percentage(1/2);
    }
    .partners-list--count-3 .partners-list__partner {
      width: percentage(1/3);
    }
    .partners-list--count-4 .partners-list__partner {
      width: percentage(1/4);
    }
  }
}

.partners-list__link {
  border: 1px solid rgb(204, 204, 204);
  height: 100%;
  padding: 1.5rem 3rem;
  display: block;

  &:hover {
    padding: 1rem 2rem;
  }

  .block--partner--five_up & {
    border: none;
    padding: 1rem 2rem;

    @media (min-width: $breakpoint-tablet) {
      padding: 1rem 2rem;
    }

    &:hover {
      padding: 1rem 2rem;
    }
  }
}

.partners-list__image {
  height: 100%;
  object-fit: contain;
  width: 100%;
  filter: grayscale(100%) brightness(0%);
  opacity: 0.4;
  transition: all 0.2s ease;

  .partners-list__link:hover & {
    filter: grayscale(0%) brightness(100%);
    opacity: 1;
  }

  .block--partner--five_up & {
    filter: grayscale(100%) brightness(0%);
    opacity: 0.4;
  }

  .block--partner--five_up .partners-list__link:hover & {
    filter: grayscale(100%) brightness(0%);
    opacity: 0.4;
  }
}

// five up
.block-partnersblock.block--partner--five_up {
  background-color: rgb(243, 243, 243);
  padding-top: 100px;
  padding-bottom: 70px;
  margin-bottom: 97px;

  @media (min-width: $breakpoint-notebook) {
    padding-top: 140px;
    padding-bottom: 50px;
    margin-bottom: 140px;
  }

  &.col-md-12 {
    padding-left: 0;
    padding-right: 0;
    margin-left: -$gutter-side-mobile + $gutter-side-tile;
    margin-right: -$gutter-side-mobile + $gutter-side-tile;
    width: 100vw;

    @media (min-width: $breakpoint-tablet) {
      margin-left: -$gutter-side-tablet + $gutter-side-tile;
      margin-right: -$gutter-side-tablet + $gutter-side-tile;
    }

    @media (min-width: $breakpoint-notebook) {
      margin-left: calc(-50vw + 50%);
      margin-right: calc(-50vw + 50%);
    }
  }
}
