// Page title region

.page-title {
  position: relative;
  text-align: center;
}

.page-title--container {
}

.page-title.page-title--bg-image {
  background-size: cover;
  background-position: 50% 50%;
  border-bottom: none;
  color: $color-white;
  margin-bottom: 4.5rem;
  padding-bottom: 0;

  a {
    color: $color-white;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &.page-title--materials-landing,
  &.page-title--about {
    &:after {
      opacity: 0.5;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 114px;
  }

  .page-title--title.page-title--title--about {
    max-width: 10em;
    font-size: 34px;
    line-height: 1;

    @media (min-width: $breakpoint-tablet) {
      font-size: 75px;
    }
  }
}

.page-title.page-title--bg-image:not(.page-title--bg-image--untinted) {
  &:after {
    background-color: $color-bg;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.2;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.page-title--info {
  .page-title.page-title--bg-image & {
    display: flex;
    min-height: 30rem;

    @media (min-width: $breakpoint-tablet) {
      min-height: 40rem;
    }
  }
  .page-title.page-title--bg-image.page-title--large & {
    min-height: 32rem;

    @media (min-width: $breakpoint-tablet) {
      min-height: 70rem;
    }
  }
}

.page-title--intro {
  font-size: 16px;
  line-height: 1.454545;
  margin-bottom: 2em;
  max-width: 782px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $breakpoint-tablet) {
    font-size: 22px;
  }

  p {
    margin-bottom: 1.454545em;
  }

  a {
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .page-title.page-title--bg-image & {
    margin-bottom: 0;
  }

  .page-title--large & {
    margin-left: auto;
    margin-right: auto;
    max-width: 73rem;
  }
}

.page-title--info {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.page-title--inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  margin-bottom: 5rem;
  width: 100%;

  .page-title.page-title--bg-image & {
    align-self: center;
    margin-bottom: 0;
  }

  .page-title.page-title--large & {
    max-width: 960px;
  }
}

.page-title--title {
  padding-top: .35em;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  .page-title.page-title--bg-image & {
    font-size: 50px;
    padding-top: 0;
    margin-bottom: 0.5rem;
    margin-top: 0;

    @media (min-width: $breakpoint-notebook) {
      font-size: 75px;
    }
  }

  .page-title.page-title--large & {
    margin-left: auto;
    margin-right: auto;
    max-width: 850px;

    @media (min-width: $breakpoint-notebook) {
      font-size: 75px;
      letter-spacing: 0;
      margin-bottom: 0.25em;
      line-height: 1;
    }
  }
}

.page-title--title.page-title--title--materials-landing {
  max-width: 880px;
}
