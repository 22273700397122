$disclosure-reveal-duration: 0.10s;
$disclosure-reveal-delay: 0.15s;

.about-image-flips {
  position: relative;
  text-align: left;
  margin-left: -10px;
  margin-right: -10px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
    }
  }

  img {
    display: block;
  }

  summary {
    transform: scale(0);
    opacity: 0;
    cursor: default;
    transition-property: opacity, transform;
    transition-duration: $disclosure-reveal-duration;
    transition-timing-function: ease-out;

    .scrolly--disclosures-revealed & {
      transform: scale(1.0);
      opacity: 1;
    }
  }

  summary::-webkit-details-marker {
    display: none
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  @media (min-width: $breakpoint-notebook) {
    margin-left: -50px;
    margin-right: -50px;
  }
}

.about-image-flip--footwear {
  .about-image-flip__heading {
    width: 55.15%;
  }

  @media (min-width: $breakpoint-smartphone-landscape) {
    max-width: 75%;
  }
}

.about-image-flip--apparel {
  float: right;
  max-width: 80%;

  .about-image-flip__heading {
    width: 38.17%;
    float: right;
  }

  @media (min-width: $breakpoint-smartphone-landscape) {
    max-width: 54%;
    margin-top: -47%;
  }
}

.about-image-flip__flipper {
  position: relative;
  clear: both;
}

.about-image-flip__heading {
  margin: 0 0 10px;
  font-size: 20px;
  line-height: 1;
  color: $color-moonshot;
  text-align: center;

  @media (min-width: $breakpoint-tablet) {
    font-size: 30px;
  }
}

.about-image-flip__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.about-image-flip__affordance {
  z-index: 3;
  position: absolute;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;

  strong {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 100%;
    border-width: 2px;
    border-style: dotted;
    border-color: $color-gray-smoke;
    text-align: center;
    background-color: $color-white;

    polygon {
      fill: $color-stone;
    }
  }

  [open] & {
    strong {
      background-color: $color-stone;
      border-style: solid;
      border-color: $color-moonshot;

      polygon {
        fill: $color-white;
      }
    }
  }

  span {
    display: none;
  }

  @media (min-width: $breakpoint-tablet) {
    width: 50px;
    height: 50px;

    strong {
      width: 36px;
      height: 36px;
      margin-top: -18px;
      margin-left: -18px;
      padding: 2px;
    }
  }
}

.about-image-flip__affordance--fw-2 { top: 10.1%; left: 35.1%; transition-delay: 0 * $disclosure-reveal-delay; }
.about-image-flip__affordance--fw-3 { top: 28.3%; left: 42.4%; transition-delay: 1 * $disclosure-reveal-delay; }
.about-image-flip__affordance--fw-4 { top: 31.8%; left: 27.1%; transition-delay: 2 * $disclosure-reveal-delay; }
.about-image-flip__affordance--fw-5 { top: 66.0%; left: 41.9%; transition-delay: 3 * $disclosure-reveal-delay; }
.about-image-flip__affordance--fw-6 { top: 77.8%; left: 49.7%; transition-delay: 4 * $disclosure-reveal-delay; }
.about-image-flip__affordance--fw-7 { top: 97.8%; left: 31.4%; transition-delay: 5 * $disclosure-reveal-delay; }
.about-image-flip__affordance--ap-2 { top: 26.0%; left: 62.2%; transition-delay: 6 * $disclosure-reveal-delay; }
.about-image-flip__affordance--ap-3 { top: 39.5%; left: 79.2%; transition-delay: 7 * $disclosure-reveal-delay; }
