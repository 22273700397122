.about-feature-tabs__header {
  padding: 12px 0 0 0;
}

.about-feature-tabs__tablist {
  display: flex;
  align-items: start;
  justify-content: center;
  padding-left: 0;
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 16px;
  line-height: 1.25;
  text-transform: uppercase;

  @media (min-width: $breakpoint-tablet) {
    font-size: 24px;
  }
}

.about-feature-tabs__tablist__tab {
  display: flex;
}

.about-feature-tabs__tablist__tab__link {
  padding: 0.5em 0.75em;
  text-decoration: none;

  &, &:hover {
    color: $color-gray-smoke;
  }

  &:focus, &:active, &[aria-selected] {
    color: $color-moonshot;
  }

  @include on-hover-capable-devices {
    &:hover {
      color: $color-moonshot;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    white-space: nowrap;
    padding: 0.5em 1.25em;
  }
}

[data-tablist-item-active=""] .about-feature-tabs__tablist__tab__link,
[data-tablist-item-active="manufacturing-scrap"] .about-feature-tabs__tablist__tab__link[href="#manufacturing-scrap"],
[data-tablist-item-active="unused-materials"] .about-feature-tabs__tablist__tab__link[href="#unused-materials"],
[data-tablist-item-active="end-of-life-shoes"] .about-feature-tabs__tablist__tab__link[href="#end-of-life-shoes"] {
  color: $color-moonshot;
}

.about-feature-tabs__tabpanel {
  &:focus {
    outline: none;
  }
}

.about-feature-tabs__heading {
  margin-bottom: 12px;
  font-size: 22px;
  line-height: 1;
  color: $color-moonshot;

  @media (min-width: $breakpoint-tablet) {
    margin-top: 30px;
    font-size: 30px;
  }

  @media (min-width: $breakpoint-notebook) {
    margin-top: 0;
  }
}

.about-feature-tabs__infopair {
  @media (min-width: $breakpoint-notebook) {
    width: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
}

.about-feature-tabs__infopair--rtl {
  @media (min-width: $breakpoint-notebook) {
    flex-direction: row-reverse;
    text-align: right;
  }
}

.about-feature-tabs__infopair__media {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  min-height: 150px;

  img {
    align-self: flex-end;
  }

  @media (min-width: $breakpoint-notebook) {
    margin-left: 25px;
    margin-right: 25px;
  }
}

.about-feature-tabs__infopair__text {
  max-width: 21em;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $breakpoint-notebook) {
    margin-left: 25px;
    margin-right: 25px;
  }
}

#manufacturing-scrap .about-feature-tabs__infopair__media {
  width: 150px;
  @media (min-width: $breakpoint-notebook) { width: 220px; }
}

#unused-materials .about-feature-tabs__infopair__media {
  width: 180px;
  @media (min-width: $breakpoint-notebook) { width: 280px; }
}

#end-of-life-shoes .about-feature-tabs__infopair__media {
  width: 118px;
  @media (min-width: $breakpoint-notebook) { width: 180px; }
}
