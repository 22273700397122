// Modals

$modal-close-button-width: 42px;

.modal--trigger {
  cursor: pointer;
  display: block;
  width: 100%;

  img {
    width: 100%;
  }
}

.modal {
  background: rgba(255, 255, 255, 0.8);
  height: 100vh;
}

.modal .modal-dialog {
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: $gutter-side-mobile;
  padding-right: $gutter-side-mobile;
  position: absolute !important;
  top: 45vh;
  width: 100%;
  max-width: 714px;
  transform: translate(-50%, -50%) !important;

  @media (min-width: $breakpoint-tablet) {
    padding-left: $modal-close-button-width + $gutter-side-tile;
    padding-right: $modal-close-button-width +$gutter-side-tile;
  } 
}

.modal .modal-content {
  border: none;
  position: relative;
}

.modal.message-modal .modal-content {
  padding: $gutter-side-tile;
}

.modal.message-modal .modal-title {
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.modal .modal-header {
  border: none;
  padding: 0;
  width: 100%;
}

.modal .modal-body {
  padding: 0;
  &.modal-padding {
    padding-bottom: 38px;
  }
}

.modal .modal-header .close {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #c4c4c4;
  border-radius: 0;
  border: none;
  height: $modal-close-button-width;
  left: 100%;
  margin-top: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: $modal-close-button-width;
  transition: all $transition-duration-default ease;

  span{
    color: $color-white;
    font-size: 28px;
    left: 50%;
    line-height: 1;
    position: absolute;
    top: 43%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background: #666;
  }

  @media (max-width: $breakpoint-tablet) {
    display: none;
  }
}

#videoModal {
  .modal-dialog {
    max-width: 1061.25px;
  }
}
