.partner-product {
  @media (min-width: $breakpoint-tablet) {
    display: flex;
  }
}

.partner-product__secondary {
  @media (min-width: $breakpoint-tablet) {
    flex: 0 0 auto;
    width: 32%;
  }
}

.partner-product__primary {
  @media (min-width: $breakpoint-tablet) {
    flex: 0 0 auto;
    margin-left: 9%;
    max-width: 582px;
    width: 59%;
  }
}

// Primary
.partner-product__title {
  font-size: 40px;
  line-height: 1;
  margin-bottom: 1.0rem;

  sup {
    font-size: 30%;
    top: -1.7em;
  }

  @media (min-width: $breakpoint-tablet) {
    font-size: 45px;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}

.partner-product__subtitle {
  font-size: 22px;
  font-family: $font-trade-gothic-bold-condensed;
  line-height: 1.1;
  margin-bottom: 1.4rem;
  text-transform: uppercase;

  @media (min-width: $breakpoint-tablet) {
    font-size: 28px;
    margin-bottom: 1.6rem;
  }

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 24px;
  }
}

.partner-product__info {
  margin: 0;
}

.partner-product__info > h3 {
  margin-bottom: 30px;
}

.partner-product__info > h5 {
  font-size: 16px;
  font-family: $font-trade-gothic-bold-condensed;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;

  @media (min-width: $breakpoint-tablet) {
    font-size: 20px;
  }
}

.partner-product__info > p,
.partner-product__info > ul {
  margin-bottom: 1.5em;

  &:last-child {
    margin-bottom: 0;
  }
}

.partner-product__info.rte {
  font-size: 14px;
  line-height: 18px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 18px;
    line-height: 1.3333;
  }

  > p {
    margin-bottom: 1.6rem;

    @media (min-width: $breakpoint-notebook) {
      margin-bottom: 24px;
    }
  }

  > h5 {
    font-size: inherit;
    margin-bottom: 0.15em;
  }
}

// Gallery
.partner-product__gallery {
  margin-bottom: 22px;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 0;
  }
}

.partner-product__gallery-main {
  height: 0;
  padding-top: 63%;
  position: relative;
}

.partner-product__gallery-main-image {
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  object-fit: contain;
  object-position: top center;
  min-height: 0;
  top: 0;
  opacity: 0;
  transition: all $transition-duration-default ease;
  visibility: hidden;
  width: 100%;
}

.partner-product__gallery-main-image.partner-product__gallery-main-image--active {
  opacity: 1;
  visibility: visible;
}

.partner-product__pager {
  display: flex;
  flex: 0 0 auto;
  list-style: none;
  justify-content: center;
  margin: 20px 0 0;
  padding: 0;
}

.partner-product__pager-item {
  flex: 0 0 auto;
}

.partner-product__pager-button {
  border: 1px solid rgb(204, 204, 204);
  cursor: pointer;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
  width: 58px;

  &:focus {
    border-radius: 0;
    outline: 1px solid black;
  }
}

.partner-product__pager-button--active {
  border-color: $color-black;
}

.partner-product__gallery-pager-image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
