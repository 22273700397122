// Homepage

.homepage {
  padding-bottom: 1rem;

  .header--admin-central {
    z-index: 3;
  }
}

.homepage--primary {
  max-height: calc(100vh - 50px);
  height: 0;
  position: relative;
  margin-bottom: 1.2rem;
  padding-top: 56%;

  @media (min-width: $breakpoint-notebook) {
    height: 840px;
    max-height: calc(100vh - 71px);
    margin-bottom: 3rem;
    padding-top: 0;
  }
}


.homepage--background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;

  video {
    height: auto;
    min-height: 100%;
    min-width: 100%;
    width: auto;
    object-fit: cover;
    min-width: 100%;

    position: absolute;
    top: unset;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media all and (-ms-high-contrast:none) {
    video {
      height: auto;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      top: unset;
      transform: translateX(-50%);
      width: auto;
    }
  }
}

.homepage--foreground {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  text-align: center;
  z-index: 3;

  @media (min-width: $breakpoint-notebook) {

  }
}

.homepage--foreground-inner {
  left: 50%;
  padding-left: $gutter-side-tile;
  padding-right: $gutter-side-tile;
  position: absolute;
  top: calc(50% + 1px);
  transform: translate(-50%, -50%);
  width: 100%;

  @media (min-width: $breakpoint-tablet) {
    padding-left: 0;
    padding-right: 0;
    top: calc(50% + 3px);
    width: 620px;
  }
}

// For homepage title styles see modules/global/_page-title.scss
.homepage--title {
  @include element-invisible();
}

.homepage--logo {
  display: inline-block;
  margin-bottom: 2rem;
  margin-top: 0rem;

  span {
    @include element-invisible();
  }

  svg {
    height: 50px;
    width: 90px;

    * {
      fill: $color-white;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    margin-top: 0;
    margin-bottom: 4rem;

    svg {
      height: 130px;
      width: 266px;
    }
  }

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 8rem;
  }
}

.page-title--title.page-title--title--homepage {
  color: $color-white;
  font-size: 36px;
  margin-top: 1.6rem;
  margin-bottom: 1rem;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 1rem;
  }

  @media (min-width: $breakpoint-tablet) {
    font-size: 75px;
    line-height: 1;
    margin-top: 3rem;
  }
}

.page-title--intro.page-title--intro--homepage {
  color: $color-white;
  margin-bottom: 0;

  &,p {
    font-family: $font-trade-gothic-bold-condensed;
    font-size: 32px;
    text-transform: uppercase;
    line-height: 1em;

    @media (min-width: $breakpoint-tablet) {
      font-size: 75px;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    color: $color-white;

    &:focus,
    &:hover {
      color: $color-white;
      text-decoration: none;
    }
  }
}
