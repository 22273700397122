// Login

.page--header--login {
  text-align: center;
}

.login--form {
  margin-bottom: 3rem;

  input {
    max-width: 100%;
  }

  @media (min-width: $breakpoint-tablet) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
    max-width: 400px;
  }
}

.login--error {
  color: rgb(200,0,0);
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: $breakpoint-tablet) {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    text-align: center;

  }
}

.login--submit-group {
  text-align: center;
}

.login--submit {
  margin-right: 1rem;
  margin-left: 1rem;
  padding-left: 2em;
  padding-right: 2em;
  transition: all $transition-duration-default ease;
}

.page-title--inner--login-forms {
  margin-bottom: 0;
  .page-title--title {
    margin-bottom: 0;
  }
}

.login--form.login--form--one-time-form, .login--form.login--form--reset {
  text-align: center;
  label {
    display: block;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    em {
     display: none;
    }
  }

  #id_email {
    display: block;
    width: 100%;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
}
.control-label[for="id_username"], .control-label[for="id_password"] {
  font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    em {
      display: none;
    }
}

.login--form--reset-text {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  text-align: center;
}

.login--form--one-time-text {
 max-width: 400px;
 margin-left: auto;
 margin-right: auto;
 margin-bottom: 20px; 
 text-align: center;
}

.password-reset-invalid {
  padding-bottom: 121px;
  text-align: center;
}

.login--reset-complete {
  text-align: center;
  padding-bottom: 121px;
}

.login--submit-group--reset-confirmation {
  margin-top: 2rem;
}

.block--form--password-reset-done {
  margin-bottom: 121px;
  text-align: center;

  p {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }
}

.page-title--reset-outer {
  margin-bottom: 19px;
}
.page-title--one-time-outer {
  margin-bottom: 19px;
}

.password-request {
  display: block;
  margin-top: 1em;
  font-size: 16px;
  line-height: 32px;
}
