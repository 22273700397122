// Custom fonts

@font-face {
    font-family: "Trade Gothic For Nike 365 Bold Condensed";
    font-style: normal;
    font-weight: 400;
    src: url('fonts/nike-trade-gothic-365-bold-condensed/257c802f-349c-4b4d-aefa-546d5de15ec6.eot');
    src: url('fonts/nike-trade-gothic-365-bold-condensed/257c802f-349c-4b4d-aefa-546d5de15ec6.eot?#iefix') format('embedded-opentype'),
    url('fonts/nike-trade-gothic-365-bold-condensed/257c802f-349c-4b4d-aefa-546d5de15ec6.woff') format('woff'),
    url('fonts/nike-trade-gothic-365-bold-condensed/257c802f-349c-4b4d-aefa-546d5de15ec6.ttf') format('truetype'),
    url('fonts/nike-trade-gothic-365-bold-condensed/257c802f-349c-4b4d-aefa-546d5de15ec6.svg#257c802f-349c-4b4d-aefa-546d5de15ec6') format('svg');
}

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=a6be08cf-ab0a-420b-93ad-da2daaa113d9");
@font-face{
  font-family:"Palatino LT W01 Roman";
  src:url("fonts/palatino/c86216ba-b6f5-44bf-8bc5-31f2efb26cd5.woff2") format("woff2"),url("fonts/palatino/b127c2c0-82cc-493d-a525-edc3aea7359d.woff") format("woff");
}
