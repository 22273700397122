.block-calloutblock {
  margin-bottom: 0;

  > h2 {
    display: none;
  }
}

.block-calloutblock--compact {
  margin-bottom: 1.2rem;
  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 2.5rem;    
  }
}

.callout {
  background-color: $color-gray-additional;
  display: flex;
  text-align: center;

  @media (min-width: $breakpoint-notebook) {
    min-height: 150px;
  }
}

.callout__image-wrapper {
  flex: 0 0 auto;
  width: 100px;

  @media (min-width: $breakpoint-desktop) {
    width: 160px;
  }  
}

.callout__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.callout__primary {
  flex: 1 1 auto;
  align-self: center;
  padding: 1.9rem 1rem 0.5rem;

  @media (min-width: $breakpoint-notebook) {
    padding-left: 7rem;
    padding-right: 7rem;
  }  

  .callout--has-image & {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @media (min-width: $breakpoint-desktop) {
      padding-left: 0.6rem;
      padding-right: 0.6rem;
    }      
  }
}

.callout__title {
  font-size: 16px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 1rem;

  @media (min-width: $breakpoint-tablet) {
    font-size: 22px;
  }

  @media (min-width: $breakpoint-desktop) {  
    font-size: 30px;
  }
}

.callout__body {
  font-size: 14px;
  line-height: 1.3333;

  @media (min-width: $breakpoint-notebook) {  
    font-size: 15px;
  }  
}