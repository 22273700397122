// Quote block

.block-quoteblock {
  background-color: $color-gray-additional;
  margin-bottom: 5rem;
  text-align: center;

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 7rem;
  }

  &.col-md-12 {
    padding-left: 0;
    padding-right: 0;
    margin-left: -$gutter-side-mobile + $gutter-side-tile;
    margin-right: -$gutter-side-mobile + $gutter-side-tile;
    width: 100vw;

    @media (min-width: $breakpoint-tablet) {
      margin-left: -$gutter-side-tablet + $gutter-side-tile;
      margin-right: -$gutter-side-tablet + $gutter-side-tile;
    }

    @media (min-width: $breakpoint-notebook) {
      margin-left: calc(-50vw + 50%);
      margin-right: calc(-50vw + 50%);
    }
  }
}

.quoteblock--wrapper {
  @media (min-width: $breakpoint-notebook) {
    display: flex;
    width: 100%;
  }
}

.quoteblock--quote-area {
  padding-top: 4rem;
  padding-bottom: 4.5rem;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $breakpoint-notebook) {
    display: flex;
    align-items: center;
    padding-top: 8rem;
    padding-bottom: 9rem;
  }

  &.quoteblock--wrapper--has-image {
    padding-left: $gutter-side-mobile;
    padding-right: $gutter-side-mobile;
    max-width: none;

    @media (min-width: $breakpoint-tablet) {
      padding-left: $gutter-side-tablet;
      padding-right: $gutter-side-tablet;
    }

    @media (min-width: $breakpoint-notebook) {
      padding-left: 110px;
    }
  }
}

.quoteblock-image-area {
  img {
    height: auto;
    width: 100%;
  }

  @media (min-width: $breakpoint-notebook) {
    flex: 0 0 auto;
    order: 2;
    width: 50%;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}

.quoteblock--quote {
  color: $color-gray-additional-2;
  float: left;
  border: none;
  margin-bottom: 0.75em;
  margin-top: 0;
  padding-top: 0px;
  font-family: $font-palatino;
  font-size: 24px;
  line-height: 1.5em;

  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: $breakpoint-tablet - 1) {
    font-size: 18px;
  }
}

.quoteblock--author {
  color: $color-gray-additional-2;
  font-family: $font-helvetica;
  font-size: 14px;
  line-height: 2em;

  @media (min-width: $breakpoint-tablet - 1) {
    font-size: 18px;
  }
}
