// Global Block styles

.block--title {
	margin-top: 0;
	text-align: center;
}

.pagesection--support {
	.block--title {
		font-size: 24px;
		line-height: 1em;

		@media (min-width: $breakpoint-tablet) {
			font-size: 36px;
			line-height: 1em;
		}
	}	
	p {
		font-size: 16px;
		line-height: 19px;
		
		@media (min-width: $breakpoint-tablet) {
			font-size: 18px;
			line-height: 24px;
		}
	}
}

h2.richtextblock--title--smaller  {
	font-size: 24px;
	line-height: 1em;

	@media (max-width: $breakpoint-notebook) {
		margin-bottom: 1px;
	}
	
}
.richtextblock--content--smaller {
	margin-bottom: -1rem;
	
	@media (min-width: $breakpoint-notebook) {
		margin-bottom: -3rem;
	}

	p {
		font-size: 15px;
		line-height: 18px;
	}

	@media (max-width: $breakpoint-notebook) {
		p {
			font-size: 14px;
			line-height: 17px;
		}
	}
}