.partner__products {
  list-style: none;
}

.partner__product {
  border-top: 2px solid rgb(235, 235, 235);
  margin-top: 40px;
  padding-top: 40px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 40px;
  }

  @media (min-width: $breakpoint-notebook) {  
    border-top: none;
    margin-top: 0;
    margin-bottom: 134px;
    padding-top: 0;

    &:last-child {
      margin-bottom: 90px;
    }
  }
}

.partner__intro {
  margin-bottom: 25px;
  text-align: center;

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 78px;
  }
}

.partner__intro-body {
  h2 {
    font-size: 25px;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 10px;

    @media (min-width: $breakpoint-tablet) {
      font-size: 45px;
      margin-bottom: 20px;
    }   
  }
}

.partner__cta {
  margin-bottom: 50px;

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 115px;
    text-align: center;
  }
}

.partner__cta-title {
  font-size: 18px;
  line-height: 1;

  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
  }
}

.partner__stories-section {
  border-top: 2px solid rgb(235, 235, 235);
  padding-top: 56px;
  padding-bottom: 56px;

  @media (min-width: $breakpoint-notebook) {
    padding-top: 110px;
    padding-bottom: 104px;
  }
}

.partner__stories-title {
  font-size: 30px;
  line-height: 1;
  margin-bottom: 3rem;
  margin-top: 0;
  text-align: center;

  @media (min-width: $breakpoint-notebook) {
    font-size: 45px;
  }
}

.partner__outro-wrapper {
  background-color: $color-gray-additional;
  clear: both;
  margin-left: calc(-50vw + 50%); 
  margin-right: calc(-50vw + 50%);
  width: 100vw;
}