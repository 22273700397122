.showcase {
  padding-left: $gutter-side-mobile;
  padding-right: $gutter-side-mobile;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 90px;
  max-width: $max-site-width;
  font-size: 14px;
  line-height: 1.29;

  @media (min-width: $breakpoint-tablet) {
    padding-left: $gutter-side-tablet - $gutter-side-tile;
    padding-right: $gutter-side-tablet - $gutter-side-tile;
  }

  @media (min-width: $breakpoint-notebook) {
    font-size: 18px;
    line-height: 1.389;
  }

  video {
    width: 100%;
  }
}

.showcase__group {
  @media (min-width: $breakpoint-tablet) {
    display: flex;
  }
}

.showcase__primary {
  @media (min-width: $breakpoint-tablet) {
    flex: 0 0 67%;
    padding-left: $gutter-side-tile;
    padding-right: $gutter-side-tile;

    .showcase--right & {
      order: 2;
    }
  }
}

.showcase__primary-group {
  margin-top: 20px;
  margin-bottom: 40px;

  @media (min-width: $breakpoint-tablet) {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 792px;
  }

  @media (min-width: $breakpoint-notebook) {
    padding-left: 45px;
  }
}

.showcase__primary-title {
  font-size: 26px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 5px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 45px;
  }
}

.showcase__primary-body {
  a {
    text-transform: uppercase;
  }
}

.showcase__primary-actions--desktop {
  margin-top: 50px;

  @media (max-width: $breakpoint-tablet - 1px) {
    display: none;
  }
}

.showcase__primary-actions--mobile {
  @media (min-width: $breakpoint-tablet) {
    display: none;
  }
}

.showcase__primary-action {
  text-transform: uppercase;
}

.showcase__secondaries {
  @media (min-width: $breakpoint-tablet) {
    flex: 0 0 33%;
    padding-left: $gutter-side-tile;
    padding-right: $gutter-side-tile;
  }
}

.showcase__secondary + .showcase__secondary {
  @media (min-width: $breakpoint-tablet) {
    margin-top: 2.5em;
  }
}

.showcase__secondary-group {
  margin-top: 20px;
  margin-bottom: 40px;

  @media (min-width: $breakpoint-tablet) {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  @media (min-width: $breakpoint-notebook) {
    .showcase--right & {
      padding-left: 45px;
    }
  }
}

.showcase__secondary-title {
  font-size: 26px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 5px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 30px;
  }
}

.showcase__secondary-body {
  a {
    text-transform: uppercase;
  }
}
