.mwng-gallery__galleries {
}

.mwng-gallery__galleries--desktop {
  margin-bottom: 11rem;

  @media (max-width: $breakpoint-notebook - 1px) {
    display: none;
  }
}

.mwng-gallery__galleries--mobile {
  margin-bottom: 10rem;
  @media (min-width: $breakpoint-notebook) {
    display: none;
  }
}

.mwng-gallery__gallery {
  position: relative;
  margin-bottom: 5px;

  &:before {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 3;
  }
}

.mwng-gallery__gallery-bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: $color-white;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  right: 0;
  height: 638px;
  width: 100%;
  visibility: hidden;
  transition: all $transition-duration-default ease;
}

.mwng-gallery__gallery-bg--default {
  opacity: 1;
  visibility: visible;

  [class^="mwng-gallery__gallery--"] & {
    opacity: 0;
    visibility: hidden;
  }
}

.mwng-gallery__gallery-inner {
  padding-top: 150px;
  position: relative;
  min-height: 638px;
  z-index: 3;
}

.mwng-gallery__intro {
  color: $color-white;
  text-align: center;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

.mwng-gallery__supertitle {
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 25px;
  line-height: 1;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.mwng-gallery__title {
  font-size: 75px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 5px;
}

.mwng-gallery__body {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 18px;

  @media (min-width: $breakpoint-desktop) {
    margin-bottom: 10px;
  }
}

.mwng-gallery__nav-wrapper {
  max-width: $max-site-width;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $breakpoint-desktop) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.mwng-gallery__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.mwng-gallery__nav-item {
  flex: 0 0 auto;
  padding: 8px;
  width: percentage(1/5);

  .mwng-gallery--count-1 &,
  .mwng-gallery--count-2 &,
  .mwng-gallery--count-3 &,
  .mwng-gallery--count-4 &,
  .mwng-gallery--count-5 &,
  .mwng-gallery--count-6 & {
    width: percentage(1/6);
  }

  @media (min-width: $breakpoint-desktop) {
    padding: 15px;
  }
}

.mwng-gallery__nav-link {
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  cursor: pointer;
  display: flex;
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 28px;
  height: 100%;
  line-height: 1;
  min-height: 92px;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: all $transition-duration-default ease;
  width: 100%;

 @media (min-width: $breakpoint-tablet) {
    font-size: 26px;
  }

  &:hover,
  &[aria-selected="true"] {
    background: rgba(255, 255, 255, 1);
  }

  &:focus {
    border-radius: 0;
    outline: 2px solid black;
  }

  [class*="mwng-gallery__gallery--inactive-"] & {
    opacity: 0.5;
  }

  span {
    pointer-events: none;
  }
}

// sections
.mwng-gallery__sections {
  max-width: $max-site-width;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 16px 30px;

  @media (min-width: $breakpoint-desktop) {
    padding: 15px 30px 30px;
  }
}

.mwng-gallery__section {
}

.mwng-gallery__section-inner {
  background-color: $color-white;
  display: flex;
  padding-bottom: 30px;
  padding-top: 30px;
}

.mwng-gallery__arrows {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  width: 90px;
}

.mwng-gallery__arrow {
  border: none;
  background: none;
  padding: 0;
  height: 40px;
  width: 40px;
}

.mwng-gallery__section-primary {
  padding-right: 35px;
  width: auto;
  min-width: 410px;
  flex: 1;
}

.mwng-gallery__section-secondary {
  width: 56%;
  max-width: 680px;
}

.mwng-gallery__section-title {
  font-size: 60px;
  line-height: 1;
  margin-top: 18px;
  margin-bottom: -5px;
}

.mwng-gallery__section-subtitle {
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 11px;
}

.mwng-gallery__section-body {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 38px;
}

.mwng-gallery__pager-title {
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 21px;
  line-height: 1;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.mwng-gallery__pager-nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 0 -30px;
  padding: 0;
}

.mwng-gallery__pager-nav--mobile {
  margin-bottom: 0;
}

.mwng-gallery__pager-nav-item {
  margin-right: 20px;
  margin-bottom: 30px;
}

.mwng-gallery__pager-link {
  background: none;
  border: none;
  padding: 4px;
  margin: -4px;
  width: 168px;
  text-align: left;

  &:focus {
    outline: none;
  }
}

.mwng-gallery__pager-image {
  .mwng-gallery__pager-link:focus & {

  }

  .mwng-gallery__pager-link[aria-selected="true"] & {
    outline: 4px solid black;
  }
}

.mwng-gallery__pager-nav-title {
  font-size: 26px;
  line-height: 1;
  margin-top: 14px;
  margin-bottom: -4px;
}

.mwng-gallery__pager-nav-subtitle {
  padding-top: 5px;
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 16px;
  line-height: 1.0625;
}

.mwng-gallery__section-secondary {
  position: relative;
  min-height: 600px;
}

.mwng-gallery__main-image--default {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.mwng-gallery__subsection {
  background-color: $color-white;
  border: 1px solid rgb(136, 136, 136);
  bottom: 0;
  left: 0;
  height: 100%;
  top: 0;
  right: 0;
  position: absolute;
}

.mwng-gallery__subsection-inner {
  display: flex;
  flex-direction: column;
}

.mwng-gallery__subsection-info {
  display: flex;
  padding: 30px 20px 20px 30px;
}

.mwng-gallery__subsection-primary {
  @media (min-width: $breakpoint-notebook) {
    z-index: 1;
    width: 60%;
    max-width: 338px;
    padding-right: 20px;
  }
}

.mwng-gallery__subsection-secondary {
  flex: 1;
  text-align: right;
}

.mwng-gallery__subsection-title {
  font-size: 32px;
  margin-top: 0;
  margin-bottom: 5px;

  @media (min-width: $breakpoint-desktop) {
    font-size: 42px;
  }
}

.mwng-gallery__subsection-body {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
}

.mwng-gallery__subsection-primary-image {
  flex: 0 0 auto;
  max-height: 300px;
  object-fit: cover;
  width: 100%;
}

.mwng-gallery__subsection-primary-image {
  height: 100%;
  object-fit: cover;
  object-position: bottom right;
  width: 100%;
}

.mwng-gallery__subsection-showcase-title {
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
}

.mwng-gallery__subsection-showcase-body {
  font-size: 18px;
  line-height: 24px;
}

.mwng-gallery__subsection-actions {
  margin-top: 30px;
   @media (min-width: $breakpoint-desktop) {
    .btn-primary {
      font-size: 14px;
    }
  }
}

.mwng-gallery__subsection-action {
  white-space: normal;
}

.mwng-gallery__subsection-secondary-logo {
  margin-bottom: 20px;
}

// Mobile
.mwng-mobile-gallery__intro-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 325px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 15px;
  align-items: center;
}

.mwng-gallery__intro {
  position: relative;
  padding-left: $gutter-side-mobile;
  padding-right: $gutter-side-mobile;
  z-index: 3;
}

.mwng-gallery__supertitle--mobile {
  font-size: 26px;
  margin-bottom: 5px;
}

.mwng-gallery__title--mobile {
  font-size: 48px;
  margin-bottom: 10px;
}

.mwng-gallery__body--mobile {
  font-size: 16px;
  line-height: 1.25;
}

.mwng-mobile-gallery__nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mwng-mobile-gallery__nav-item {
  margin-bottom: 15px;
}

.mwng-mobile-gallery__accordion {

}

.mwng-mobile-gallery__accordion-button {
  background: none;
  border: 2px solid rgb(224, 224, 224);
  padding: 22px 15px 14px 140px;
  position: relative;
  text-align: left;
  width: 100%;
}

.mwng-mobile-gallery__accordion-label {
  font-size: 28px;
  line-height: 1;
  font-family: $font-trade-gothic-bold-condensed;
  text-transform: uppercase;
}

.mwng-mobile-gallery__accordion-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  object-fit: cover;
  width: 120px;
}

.mwng-mobile-gallery__accordion-content {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all $transition-duration-default ease;

  &.mwng-mobile-gallery__accordion-content--active {
    max-height: 1500px;
    opacity: 1;
    visibility: visible;

  .mwng-mobile-gallery:last-child .mwng-mobile-gallery__nav-item:last-child & {
      border-bottom: 2px solid #e0e0e0;
      padding-bottom: 15px;
    }
  }
}

.mwng-mobile-gallery__default-image {
  width: 100%;
}

.mwng-mobile-gallery__default-text {
  padding-left: $gutter-side-mobile;
  padding-right: $gutter-side-mobile;
  margin-bottom: 40px;
}

.mwng-gallery__section-title--mobile {
  font-size: 28px;
  margin-top: 20px;
}

.mwng-gallery__section-subtitle--mobile {
  font-size: 18px;
  margin-bottom: 5px;
}

.mwng-gallery__section-body--mobile {
  font-size: 14px;
  line-height: 1.2858;
}

// pager
.mwng-gallery__pager--mobile {
  padding-left: $gutter-side-mobile;

  @media (min-width: $breakpoint-tablet) {
    padding: 20px;
  }
}

.mwng-gallery__pager-title--mobile {
  font-size: 14px;
}

.mwng-gallery__pager-nav-item--mobile {
  margin-right: 15px;
  margin-bottom: $gutter-side-mobile;
}

.mwng-gallery__pager-link--mobile {
  width: 100px;
}

.mwng-gallery__pager-nav-title--mobile {
  font-size: 17px;
  letter-spacing: -0.5px;
  margin-top: 11px;
  margin-bottom: 0;
}

.mwng-gallery__pager-nav-subtitle--mobile {
  font-size: 14px;
  line-height: 1.0625;
}

.mwng-gallery__subsection--mobile {
  border: none;
  position: static;
  height: auto;
  margin-bottom: 5.0rem;
}

.mwng-gallery__subsection-info--mobile {
  padding: 20px 20px 0;
}

.mwng-mobile-gallery__subsection-gallery {
  display: flex;
  width: 100%;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
/* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.mwng-mobile-gallery__subsection-gallery-inner-image {
  flex: 0 0 auto;
  scroll-snap-align: start;
  width: 100%;
}

.mwng-gallery__subsection-inner--mobile {
  display: block;
}

.mwng-mobile-gallery__dots {
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    border: none;
    background: none;
    font-size: 40px;
    height: 30px;
    line-height: 0;
    padding: 0;
    width: 20px;
    position: relative;

    span:before {
      border: 2px solid rgb(204, 204, 204);
      border-radius: 100%;
      content: "";
      position: absolute;
      background-color: rgb(244, 244, 244);
      height: 12px;
      width: 12px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    span.mwng-mobile-gallery__dot--active {
      &:before {
        background-color: rgb(204, 204, 204);
      }
    }
  }
}

.mwng-gallery__subsection-title--mobile {
  font-size: 28px;
  margin-bottom: 5px;
}

.mwng-gallery__subsection-body--mobile {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 18px;
}

.mwng-gallery__subsection-showcase-title--mobile {
  font-size: 14px;
  line-height: 18px;
}

.mwng-gallery__subsection-showcase-body--mobile {
  font-size: 14px;
  line-height: 18px;
}

.mwng-gallery__subsection-actions--mobile {
  margin-top: 18px;
}

// tablet
.mwng-mobile-gallery__main-content {
  padding-bottom: 20px;
}
