// Statblock

.block-statblock {
  margin-bottom: 5rem;
  overflow: visible;
  text-align: center;

  @media (max-width: $breakpoint-tablet) {
    .block-pagesectionblock & {
      float: none;
      margin-left: auto;
      margin-right: auto;
      max-width: unset;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 7rem;
  }
}

.statblock--primary {
  border-bottom: 2px solid transparent;
  padding-bottom: 1.6rem;
  position: relative;

  @media (min-width: $breakpoint-tablet) {
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100px;
    height: 2px;
    border-bottom: 2px solid $color-gray-xtra-light;
  }
}
// IE HACKS
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  .statblock--primary::after {
    left: 50%;
    transform: translateX(-50%);
    right: 0;
  }
}
@supports (-ms-ime-align: auto) { // MS EDGE
  .statblock--primary::after {
   left: 50%;
   transform: translateX(-50%);
   right: 0;
 }
}

.statblock--number {
  color: $color-moonshot;
  display: block;
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 50px;
  line-height: 1.2em;
  letter-spacing: $header-letter-spacing;
  line-height: 1;
  
  @media (min-width: $breakpoint-tablet) {
    font-size: 75px;
    line-height: 80px;
  }
}

.statblock--unit {
  color: $color-moonshot;
  display: block;
  font-family: $font-trade-gothic-bold-condensed;  
  font-size: 25px;
  line-height: 1em;
  letter-spacing: $header-letter-spacing;
  line-height: 1;
  text-transform: uppercase;

  @media (min-width: $breakpoint-tablet) {
    font-size: 31px;
    line-height: 1em;
  }
}

.statblock--title {
  color: #7b7b7b;
  font-size: 13px;
  line-height: 1em;
  font-family: $font-trade-gothic-bold-condensed;  
  letter-spacing: $header-letter-spacing;
  margin-top: 1.4rem;
  text-transform: uppercase;

  @media (min-width: $breakpoint-tablet) {
    font-size: 20px;
    line-height: 21px;
  }
}

.statblock--image {
  margin: 0 auto;
  height: 112px;
  width: auto;
}
