// Footer styles

#footer {
  background-color: $color-bg;
  color: $color-white-dark;
  max-width: 100vw;
  overflow: hidden;

  .homepage & {
    @media (min-width: $breakpoint-notebook) {
      flex: 0 0 auto;
    }
  }
}

.footer--menu {

  @media (min-width: $breakpoint-notebook) {
    padding-top: 3rem;
    padding-bottom: 3rem;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}

#footer--menu {
  min-height: 0;
  transition: min-height 0.5s ease $transition-duration-default;
}

.footer--links-primary {
  margin-bottom: 0;
  padding-left: 0;
  position: relative;

  @media (max-width: $breakpoint-notebook) {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
}

.footer--nav-li {
  @media (min-width: $breakpoint-notebook) {
    display: inline-block;
    margin-right: 7rem;
    vertical-align: top;
  }  
}

.footer--nav-item {
  @media (min-width: $breakpoint-notebook) {
    color: $color-white;
    display: block;
    font-family: $font-trade-gothic-bold-condensed;
    font-size: 14px;
    line-height: 1.2;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-decoration: none;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: $color-white;
      text-decoration: underline;
    }
  }
}

.footer--nav-submenu.mobile-menu--nav-submenu li {
  @media (min-width: $breakpoint-notebook) {
    display: block;
    margin-right: 0;
  }  

  a {
    @media (min-width: $breakpoint-notebook) {
      color: $color-white-dark;
      font-family: $font-helvetica;
      font-weight: 300;
      font-size: 13px;
      padding-bottom: 0.5em;
      padding-top: 0.5em;
      text-transform: none;

      &:hover,
      &:focus {
        color: $color-white;
      }
    }
  }
}

.footer--nav-submenu.mobile-menu--nav-submenu {
  @media (min-width: $breakpoint-notebook) {
    display: block;
  }
}

.footer--secondary {
  font-weight: 300;
  margin-bottom: 0;
  text-align: center;

  @media (min-width: $breakpoint-notebook) {
    padding-top: 25px;
  }
}

.footer--links-secondary {
  flex: 0 0 auto;
  list-style: none;
  margin-bottom: 0;  
  padding-left: 0;

  > li {
    display: inline-block;

    a {
      color: $color-white-dark;
      display: block;
      font-size: 12px;
      line-height: 1em;
      padding: 5px 7px;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $color-white;
        text-decoration: underline;
      }

      @media (max-width: $breakpoint-notebook) {
        padding: 10px;
      }

      @media (min-width: $breakpoint-notebook) {
        font-size: 13px;
        line-height: 16px;
      }
    }      
  }
}

.footer--secondary-inner {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @media (min-width: $breakpoint-notebook) {
    border-top: 1px solid rgb(51, 51, 51);    
    display: flex;
  }  
}

.footer-copyright {
  align-self: center;
  display: block;
  flex: 1 1 auto;
  font-size: 12px;
  line-height: 1em;

  @media (max-width: $breakpoint-notebook) {
    border-bottom: 1px solid rgb(51, 51, 51); 
    margin-left: -$gutter-side-mobile;
    margin-right: -$gutter-side-mobile;
    margin-bottom: 1.5rem;
    padding-left: $gutter-side-mobile;
    padding-right: $gutter-side-mobile;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  @media (min-width: $breakpoint-notebook) {
    text-align: left;
    font-size: 13px;
    line-height: 16px;
  }
}