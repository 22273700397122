.gallery-slider {
  margin-bottom: 6rem;  

  @media (max-width: $breakpoint-tablet) {
    margin-left: -$gutter-side-mobile;
    margin-right: -$gutter-side-mobile;
  }

  .flickity-prev-next-button {
    top: calc(50% - 15px);
  }
}

.gallery-slider__item {
  padding-left: 4px;
  padding-right: 4px;
  width: 45%;

  @media (min-width: $breakpoint-tablet) {    
    margin-left: 0;
    transform: none;
    width: 25%;
  }
}

.gallery-slider__inner {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.gallery-slider__item-image {
  min-height: 0;
  margin-block: auto;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.gallery-slider__item-title {
  margin-top: -30px;
  flex: 0 0 auto;
  font-family: $font-helvetica;
  font-size: 13px;
  line-height: 1;
  padding-top: 8px;
  margin-bottom: 0;
  text-transform: none;
  text-align: center;
  min-height: 26px; // 2 lines
  
  @media (min-width: $breakpoint-tablet) {
    font-size: 15px;
    min-height: 30px;
  }
}

.gallery-slider__link {
  text-decoration: none;
}

.gallery__actions {
  margin-top: -3rem;
  margin-bottom: 6rem;    
  text-align: center;
}

.gallery__action {
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;

  @media (min-width: $breakpoint-notebook) {  
    font-size: 16px;
    line-height: 1.25
  }  
}