// vars
$materials-content-width: 1020px;

// intro
.materials-landing__intro {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
  padding-left: $gutter-side-mobile;
  padding-right: $gutter-side-mobile;
  text-align: center;
  width: 100%;
}

.materials-landing__intro-title {
  font-size: 26px;
  margin-top: 0;
  line-height: 1;
  margin-bottom: 10px;

  @media (min-width: $breakpoint-tablet) {
    font-size: 45px;
    margin-top: 114px;
    margin-bottom: 15px;
  }
}

.materials-landing__background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;

  video {
    height: auto;
    min-height: 100%;
    min-width: 100%;
    width: auto;
    object-fit: cover;
    min-width: 100%;

    position: absolute;
    top: unset;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

// materials list
.materials-landing__materials-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 5.5rem;
  padding-bottom: 2.5rem;
  position: relative;
  width: 100%;
  justify-content: center;

  &:after {
    border-bottom: 2px solid rgb(235, 235, 235);
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 250px;
  }

  @media (min-width: $breakpoint-tablet) {
    border-bottom: 2px solid rgb(235, 235, 235);
    max-width: $max-site-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 8rem;
    padding-bottom: 4.5rem;

    &:after {
      display: none;
    }
  }
}

.materials-landing__material-item {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 2.4rem;
  width: 50%;
  text-align: center;

  @media (min-width: $breakpoint-tablet) {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 3rem;
    width: 25%;
  }
}

.materials-landing__material-link {
  display: flex;
  flex-direction: column;
  height: 100%;

  &:hover {
    color: rgb(153, 153, 153);
  }
}

.materials-landing__material-image-wrapper {
  flex: 1 1 auto;
}

.materials-landing__material-image {
  width: 100%;
  transform: scale(1);
  transition: all $transition-duration-default ease;

  .materials-landing__material-link:hover & {
    transform: scale(1.17);
  }
}

.materials-landing__material-label {
  flex: 0 0 auto;
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 16px;
  line-height: 1.5625;
  display: block;
  position: relative;
  text-transform: uppercase;

  @media (min-width: $breakpoint-tablet) {
    font-size: 24px;
    line-height: 2;
  }
}

// standards
.materials-landing__standards {
  padding-left: $gutter-side-mobile;
  padding-right: $gutter-side-mobile;
  margin-bottom: 4.7rem;

  @media (min-width: $breakpoint-tablet) {
    display: flex;
    max-width: $materials-content-width;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }

  @media (min-width: $breakpoint-notebook) {
    padding-left: 0;
    padding-right: 0;
  }
}

.materials-landing__standard {
  align-items: flex-start;
  display: flex;
  margin-bottom: 3.5rem;

  @media (min-width: $breakpoint-tablet) {
    flex: 0 0 auto;
    width: 50%;

    &:first-child {
      padding-right: 30px;
    }

    &:last-child {
      padding-left: 50px;
    }
  }
}

.materials-landing__standard-image-wrapper {
  align-self: center;
  flex: 0 0 auto;
  height: 60px;
  width: 60px;
  margin-right: 20px;
  margin-bottom: 15px;

  @media (min-width: $breakpoint-tablet) {
    height: 88px;
    width: 88px;
    margin-right: 20px;
  }
}

.materials-landing__standard-image {
  height: 100%;
  object-fit: contain;
  object-position: center;
  width: 100%;
}

.materials-landing__standard-title {
  font-size: 20px;
  line-height: 1.15;
  margin-top: 0;
  margin-bottom: 5px;

  @media (min-width: $breakpoint-tablet) {
    font-size: 30px;
    line-height: 1.5;
    margin-bottom: 6px;
  }
}

.materials-landing__standard-body.rte {
  font-size: 14px;
  line-height: 1.286;

  @media (min-width: $breakpoint-tablet) {
    font-size: 16px;
    line-height: 1.5;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.materials-landing__standards-cta {
  margin-bottom: 10rem;
  text-align: center;
}

// sources
.materials-landing__source-wrapper {
  background-color: $color-gray-additional;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

.materials-landing__source-inner {
  padding-left: $gutter-side-mobile;
  padding-right: $gutter-side-mobile;

  @media (min-width: $breakpoint-tablet) {
    max-width: $materials-content-width;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: $breakpoint-notebook) {
    padding-left: 0;
    padding-right: 0;
  }
}

.materials-landing__source-title {
  margin-top: 0;
  font-size: 26px;
  line-height: 1;
  margin-bottom: 50px;

  @media (min-width: $breakpoint-tablet) {
    font-size: 45px;
  }
}

.materials-landing__sources {
  display: flex;
  max-width: 760px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 50px;
  }
}

.materials-landing__source {
  flex: 0 1 auto;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.materials-landing__source-label {
  font-size: 16px;
  line-height: 1;
  margin-top: 15px;
  margin-bottom: 0;

  @media (min-width: $breakpoint-tablet) {
    font-size: 24px;
    margin-top: 24px;
  }
}

.materials-landing__source-image-wrapper {
  height: 50px;
  width: 100%;

  @media (min-width: $breakpoint-tablet) {
    height: 120px;
  }
}

.materials-landing__source-image {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

// outro
.materials-landing__source-outro.rte {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
    line-height: 1.3889;
  }
}

.materials-landing__source-link {
  text-transform: uppercase;

  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
  }
}

.materials-landing__outro {
  max-width: $max-site-width;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $breakpoint-tablet) {
    display: flex;
  }
}

.materials-landing__outro-primary {
  padding-left: $gutter-side-mobile;
  padding-right: $gutter-side-mobile;
  padding-top: 45px;
  padding-bottom: 90px;
  text-align: center;

  @media (min-width: $breakpoint-tablet) {
    align-self: center;
    flex: 0 0 500px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 6rem;
    padding-bottom: 6rem;
    text-align: left;
  }
  @media (min-width: $breakpoint-notebook) {
    flex: 0 0 500px;
    padding-right: 100px;
  }
}

.materials-landing__outro-title {
  font-size: 26px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 5px;

  @media (min-width: $breakpoint-tablet) {
    font-size: 45px;
    margin-bottom: 15px;
  }
}

.materials-landing__outro-body.rte {

  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
    line-height: 1.3889;
  }
}

.materials-landing__outro-link {
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;

  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
  }
}

.materials-landing__outro-image-wrapper {
  @media (min-width: $breakpoint-tablet) {
    flex: 1 1 auto;
  }
}

.materials-landing__outro-image {
  min-height: 100%;
  object-fit: cover;
  width: 100%;
}
