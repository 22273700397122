// Rich text block styles

.block-richtextblock {
	margin-bottom: 4rem;

	@media (min-width: $breakpoint-tablet) {
		margin-bottom: 6rem;
	}

	> h2 {
		text-align: center;
	  font-size: 25px;

	  @media (min-width: $breakpoint-tablet) {
	    font-size: 45px;
	  }		
	}
}

.block-richtextblock.richtextblock--no-margin {
	margin-bottom: 0;
}

.richtextblock--content--no-bottom-padding { 
	margin-bottom: -3rem;
	
	@media (min-width: $breakpoint-tablet) {
		margin-bottom: -5rem;
	}
	
	p {
		@media (max-width: $breakpoint-tablet) {
			font-size: 16px;
			line-height: 19px;
		}	
	}

}