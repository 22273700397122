.about__hero {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;

  video {
    height: auto;
    min-height: 100%;
    min-width: 100%;
    width: auto;
    object-fit: cover;
    min-width: 100%;
    position: absolute;
    top: unset;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
