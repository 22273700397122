.about-feature {
  max-width: $max-site-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $gutter-side-mobile;
  padding-right: $gutter-side-mobile;
  font-size: 16px;
  line-height: 1.25;

  @media (min-width: $breakpoint-tablet) {
    font-size: 20px;
    line-height: 1.5;
  }
}

.about-feature--centered {
  text-align: center;
}

.about-feature--narrow {
  max-width: 800px;
}

.about-feature--diminished {
  font-size: 14px;
  line-height: 18px;

  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
    line-height: 25px;
  }
}

.about-feature__title {
  font-size: 26px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 10px;

  @media (min-width: $breakpoint-tablet) {
    font-size: 45px;
    margin-bottom: 15px;
  }
}

.about-feature__title--smaller {
  font-size: 19px;

  @media (min-width: $breakpoint-smartphone-portrait) {
    font-size: 23px;
  }

  @media (min-width: $breakpoint-tablet) {
    font-size: 45px;
  }
}

.about-feature__title--impactful {
  font-size: 36px;
  line-height: 1;
  color: $color-moonshot;

  em {
    display: block;
    font-size: 22px;
    line-height: 1;
    font-style: normal;
    color: $color-gray-dark;
  }

  @media (min-width: $breakpoint-tablet) {
    font-size: 72px;

    em {
      font-size: 45px;
    }
  }
}

.about-feature__actions {
  margin-top: 25px;

  @media (min-width: $breakpoint-tablet) {
    margin-top: 60px;
  }
}

.about-feature__actions--tight {
  margin-top: 24px;
}

.about-feature__more-link {
  display: inline-block;
  line-height: 18px;
  text-transform: uppercase;

  @media (min-width: $breakpoint-tablet) {
    font-size: 16px;
  }
}

.about-whatis {
  padding-top: 40px;
}

.about-feature__outro {
  padding-bottom: 100px;
  overflow-x: hidden;

  @media (min-width: $breakpoint-notebook) {
    align-items: center;
    display: flex;
    padding-bottom: 6px;
  }
}

.about-feature__outro__media {
  margin-left: -$gutter-side-mobile;
  margin-right: -$gutter-side-mobile;

  @media (min-width: $breakpoint-notebook) {
    flex: 0 0 auto;
    width: 57%;
    margin-right: 0;
  }
}

.about-feature__outro__text {
  margin-top: 30px;

  @media (min-width: $breakpoint-notebook) {
    flex: 1 1 auto;
    margin-top: 0;
    padding-left: 25px;
    max-width: 29.5em;
  }
}

.about-feature__outro__title {
  @media (min-width: $breakpoint-notebook) {
    margin-top: 0;
  }
}

.about-feature__outro__polyptych {
  margin-top: 0;
  margin-bottom: 0;
}

