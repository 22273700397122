.flickity-prev-next-button {
  background-color: $color-white;

  svg {
    left: 50%;
    top: 50%;
    height: 15px;
    width: 15px;
    transform: translate(-50%, -50%);
  }
}

.flickity-prev-next-button.previous {
  left: 0;
  transform: translate(-50%, -50%);

  @media (max-width: $breakpoint-tablet - 1px) {
    display: none;
  }
}
.flickity-prev-next-button.next {
  right: 0;
  transform: translate(50%, -50%);

  @media (max-width: $breakpoint-tablet - 1px) {
    display: none;
  }
}
