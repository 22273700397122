// CTA blocks

.cta--content {
  display: inline-block;
}

.block-calltoactionblock {
  margin-bottom: 2rem;
  text-align: center;

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 6rem;
  }
}

.block-calltoactionblock--default {
  margin-bottom: 115px;
}

.block-calltoactionblock--default > h2 {
  font-size: 18px;
  line-height: 1;
  margin-bottom: 5px;
}

.cta--text {
  margin-bottom: 1.25em;
}

.cta--link {
  @include btn();
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .cta-green & {
    @include btn($color-fresh-turf, $color-body);
  }

  @media (min-width: $breakpoint-tablet) {
    .cta--text & {
      width: 80%;
    }
  }
}

// CTA image
.block-calltoactionblock.block-calltoactionblock--background_image {
  margin-bottom: 3px;
  padding-left: 0;
  padding-right: 0;

  &:first-child {
    margin-left: -$gutter-side-mobile + $gutter-side-tile;
    margin-right: -$gutter-side-mobile + $gutter-side-tile;
    width: calc(100% + 10px);
  }

  &:last-child {
    padding-right: 0;
    margin-left: -$gutter-side-mobile + $gutter-side-tile;
    margin-right: -$gutter-side-mobile + $gutter-side-tile;
    width: calc(100% + 10px);
  }

  > h2 {
    display: none;
  }

  @media (min-width: $breakpoint-tablet) {
    padding-left: 3px;
    padding-right: 3px;

    &:first-child {
      margin-left: -$gutter-side-tablet + $gutter-side-tile;
      margin-right: 0;
      padding-left: 0;

      &.col-md-6 {
        width: calc(50% + 15px;);
      }
    }

    &:last-child {
      margin-right: -$gutter-side-tablet + $gutter-side-tile;
      margin-left: 0;
      padding-right: 0;

      &.col-md-6 {
        width: calc(50% + 15px;);
      }
    }
  }
}

.cta-image {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 320px;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all $transition-duration-default ease;

  &:after {
    background-color: rgba(0,0,0,0.5);
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all $transition-duration-default ease;
  }

  &:hover {
    background-size: 150% auto;

    &:after {
      opacity: 1;
    }
  }

  @media (min-width: $breakpoint-notebook) {
    min-height: 420px;
  }
}

.cta-image--story {

  .stories--wrapper--partners & {
    min-height: 250px;
  }

  @media (min-width: $breakpoint-notebook) {
    min-height: 384px;

    .stories--wrapper--partners & {
      min-height: 250px;
    }
  }
}

.cta-image__background {
  background-size: cover;
  background-position: center center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transform: scale(1);
  transition: all $transition-duration-default ease;
  position: absolute;

  a.cta-image:hover & {
    transform: scale(1.5);
  }
}

.cta-image__primary {
  background-color: rgba(255, 255, 255, 0.85);
  flex: 0 0 auto;
  min-width: 175px;
  padding: 0.4em 0.7em 0.7em;
  position: relative;
  z-index: 3;

  @media (min-width: $breakpoint-tablet) {
    min-width: 350px;
  }
}

.cta-image__primary--story {
  width: 300px;

  @media (min-width: $breakpoint-tablet) {
    padding-left: 24px;
    padding-right: 24px;
    width: 456px;

    .stories--wrapper--partners & {
      width: 300px;
    }
  }
}

.cta-image__title {
  font-size: 60px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: $breakpoint-tablet) {
    font-size: 75px;
  }
}

.cta-image__title--story {
  font-size: 32px;
  line-height: 1em;

  .stories--wrapper--partners & {
    font-size: 28px;
  }

  @media (min-width: $breakpoint-tablet) {
    font-size: 40px;

    .stories--wrapper--partners & {
      font-size: 28px;
    }
  }
}

.cta-image__body {
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 16px;
  margin-top: 0;
  text-transform: uppercase;

  p:last-child {
    margin-bottom: 0;
  }

  .stories--wrapper--partners & {
    font-size: 15px;
  }

  @media (min-width: $breakpoint-tablet) {
    font-size: 20px;

    .stories--wrapper--partners & {
      font-size: 15px;
    }
  }
}

.cta-image__body--story {
  margin-top: 0;

  @media (min-width: $breakpoint-tablet) {
    margin-top: 0;
  }
}
