.applications {
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 97px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 140px;
    font-size: 18px;
    line-height: 25px;
  }
}

.applications__primary {
  @media (min-width: $breakpoint-notebook) {
    display: flex;
    margin-bottom: 86px;
  }
}

.applications__application {
  margin-bottom: 40px;

  @media (min-width: $breakpoint-notebook) {
    flex: 0 0 50%;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 0;
  }
}

.applications__image {
  max-height: 50px;

  @media (min-width: $breakpoint-notebook) {
    max-height: none;
  }
}

.application__title {
  font-size: 20px;
  line-height: 1;
  margin-top: 11px;
  margin-bottom: 5px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
}

.application__body {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;

  p {
    margin-bottom: 10px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.applications__action {
  text-transform: uppercase;
}
