.about-stat {
  font-family: $font-trade-gothic-bold-condensed;
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  max-width: 15em;

  @media (min-width: $breakpoint-tablet) {
    font-size: 30px;
  }
}

.about-stat__number {
  display: block;
  font-size: 100px;
  line-height: 1;
  color: $color-moonshot;

  @media (min-width: $breakpoint-tablet) {
    font-size: 200px;
  }
}

.about-stat__unit {
  display: block;
  font-size: 30px;
  line-height: 1;
  color: $color-moonshot;
  margin-top: -0.3em;
  margin-bottom: 0.2em;

  @media (min-width: $breakpoint-tablet) {
    margin-bottom: 0.15em;
    font-size: 60px;
  }
}
