.contact__inner {
  margin-bottom: 6rem;
  min-height: 56rem;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 15rem;
    min-height: 50rem;
  }
}

.contact__inner--thanks {
  @media (min-width: $breakpoint-tablet) {
    margin-left: auto;
    margin-right: auto;
    max-width: 750px;
  }
}

.contact__page-title-region {
  margin-bottom: 2.5rem;
  margin-top: 6rem;
  text-align: center;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 5rem;
    margin-top: 15rem;
  }
}

.contact__page-title {
  font-size: 45px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 75px;
  }
}

.contact__page-title--thanks {
  margin-bottom: 1.5rem;
}

.contact__sections {
  margin-bottom: 8rem;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 16rem;
  }
}

.contact__section {
  display: none;
}

.contact__section-header {
  margin-bottom: 5rem;
  text-align: center;
}

.contact__section-title {
  font-size: 33px;

  @media (min-width: $breakpoint-notebook) {
    font-size: 45px;
  }
}

.contact__section--active {
  display: block;
}

.contact__form-group {
  @media (min-width: $breakpoint-tablet) {
    display: flex;
    justify-content: space-between;
  }
}

.contact__form-field {
  @media (min-width: $breakpoint-tablet) {
    flex: 0 0 auto;
    width: calc(50% - 1.4rem);
  }
}

.contact__form-field--full {
  width: 100%;
}

.contact__select-label {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 3rem;

  @media (min-width: $breakpoint-notebook) {
    font-size: 20px;
  }
}

.contact__select {
  @include fancy-select();

  font-family: Helvetica, sans-serif;
  text-transform: none;
  font-size: 16px;
  line-height: 19px;

  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
  }

  > option {
    font-size: inherit;
  }
}

.contact__form-label {
  display: block;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.2em;
  // letter-spacing: 0.02em;

  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
  }
}

.contact__form-input {
  @include fancy-textfield();
  font-size: 16px;
  line-height: 19px;

  @media (min-width: $breakpoint-tablet) {
    font-size: 18px;
    line-height: 1em;
  }
}

.contact__form-input--textarea {
  line-height: 1.2em;
  min-height: 8.4em;
  margin-bottom: 5rem;
}

.contact__form-fieldset {
  flex: 1 1 auto;
  margin-top: 5rem;
  margin-bottom: 3.5rem;
  width: 100%;

  legend {
    border-bottom: none;
    font-size: 16px;
    margin-bottom: 10px;

    @media (min-width: $breakpoint-notebook) {
      font-size: 18px;
    }
  }
}

.contact__form-actions {
  position: relative;
  text-align: center;
  z-index: 5;
}

// checkmarks
.contact__form-checkbox-group {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 1.5rem;
  margin-right: 2.4rem;
  padding-left: 22px;
  position: relative;

  @media (min-width: $breakpoint-notebook) {
    font-size: 18px;
    margin-right: 1.6rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.contact__form-checkbox {
  @include element-invisible();
}

.contact__form-checkbox-label {
  &:before {
    left: 0;
    content: "";
    height: 15px;
    border: 1px solid rgb(205, 210, 214);
    top: 4px;
    position: absolute;
    width: 15px;
  }

  &:after {
    content: "";
    display: none;
    background-image: url('images/checkmark.png');
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    left: 0;
    position: absolute;
    top: 4px;
    height: 15px;
    width: 15px;
  }

  .contact__form-checkbox:checked + & {
    &:after {
      display: block;
    }
  }

  .contact__form-checkbox:focus + & {
    &:before {
      outline: 2px solid #001dc4;
    }
  }
}

.g-recaptcha > div {
  margin-left: auto;
  margin-right: auto;
}
