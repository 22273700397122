.external-link {
  svg {
    position: relative;
    display: inline-block;
    top: 0.15em;
    width: 1em;
    height: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    vertical-align: baseline;
  }

  &.cta--link, &.btn, &.btn-primary {
    svg {
      margin-right: -0.15em;
    }
  }

  &.footer--nav-item {
    svg {
      display: none;
    }
  }
}

