.about-polyptych {
  font-size: 0;
}

.about-polyptych--triptych {
  margin-left: -3px;
  margin-right: -3px;

  img {
    width: percentage(1/3);
    padding-left: 3px;
    padding-right: 3px;
  }
}

.about-polyptych--heptaptych {
  margin-left: -10px;
  margin-right: -10px;

  img {
    width: percentage(1/6);
  }
}

.about-polyptych--wide {
  @media (min-width: $breakpoint-notebook) {
    margin-left: -100px;
    margin-right: -100px;
  }
}
